import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import '@fontsource/abeezee';

// A custom theme for this app
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#673ab7',
      dark: '#320b86',
      light: '#9a67ea',
    },
    secondary: {
      main: '#7986cb',
      dark: '#49599a',
      light: '#aab6fe',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'ABeeZee',
    fontSize: 16,
  },
});

export default defaultTheme;
