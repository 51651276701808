import React, { createContext, useContext, useState } from 'react';
import { useMediaQuery } from '@mui/material';

const GlobalContext = createContext<any>(undefined);

const GlobalProvider = ({ children }: any) => {
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [infoModalAccepted, setInfoModalAccepted] = useState<boolean>(false);

  const underSixHundred = useMediaQuery('(max-width:600px)');
  const underSevenFiftyHundred = useMediaQuery('(max-width:750px)');
  const underNineHundred = useMediaQuery('(max-width:900px)');

  const value = {
    infoModalOpen,
    setInfoModalOpen,
    infoModalAccepted,
    setInfoModalAccepted,
    underSixHundred,
    underSevenFiftyHundred,
    underNineHundred,
  };

  return (
    <GlobalContext.Provider value={value}>{children} </GlobalContext.Provider>
  );
};

const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }

  return context;
};

export { GlobalProvider, useGlobalContext };
