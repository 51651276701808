/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import TopLayout from './TopLayout';
import { GlobalProvider } from '../../src/context/GlobalContext';

export const wrapRootElement = ({ element }: any) => {
  return (
    <GlobalProvider>
      <TopLayout>{element}</TopLayout>
    </GlobalProvider>
  );
};
